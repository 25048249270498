import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import Card from "../components/Card/Card.js"
import GridSpan from "../components/GridSpan/GridSpan.js"

import Button from "../components/Button/Button.js"

import * as FontAwesome from 'react-icons/fa'
import { GatsbySeo } from 'gatsby-plugin-next-seo';

export default function HomePage({data}) {
  return (
      <Layout>
        <GatsbySeo 
          title="The Ultimate Toolkit for Artists"
          description="This is the page description."
        />

        <GridSpan span={[12, 12, 12, 12]}>
          <Card>
            <GridSpan span={[2,2,2,2]} justifyItems="center">
              <Img style={{width: "100%", maxWidth: "480px", marginLeft: "auto", marginRight: "auto"}}
                fluid={data.mockup_insights.childImageSharp.fluid}
                alt="Mockup of application."
              />
              <h1 style={{maxWidth: "480px", marginLeft: "auto", marginRight: "auto", textAlign: "center"}}>analytics for musicians</h1>
              <h5 style={{maxWidth: "480px", marginLeft: "auto", marginRight: "auto", color: "var(--neutral-03)", textAlign: "center"}}>Gain valuable insights about your streaming and social media.</h5>
              <h5 style={{maxWidth: "480px", marginLeft: "auto", marginRight: "auto", color: "var(--neutral-03)", textAlign: "center"}}>Track all your analytics in one place.</h5>
              <Button
                to="/waitlist"
                icon={<FontAwesome.FaArrowRight/>}
              >
                join waitlist
              </Button>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,12,12,12]}>
          <Card>
            <GridSpan span={[2,1,1,1]}>
              <Img
                fluid={data.bench_guitarman.childImageSharp.fluid}
                alt="Streaming sources for the application."
                style={{
                  width: "100%",
                  margin: "auto",
                  justifySelf: "stretch",
                  background: "var(--blue-07)",
                  borderRadius: 8
                }}
              />
            </GridSpan>
            <GridSpan span={[2,1,1,1]} justifyItems="start">
              <h2 style={{textAlign: "left", width: "100%"}}>music industry guide</h2>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>We do the boring stuff so you can focus on making music.</h5>
              <Button
                to="/about"
                btnType="secondary"
                icon={<FontAwesome.FaArrowRight/>}
              >
                learn more
              </Button>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Card>
            <GridSpan span={[2,2,2,2]}>
              <h2 style={{color: "var(--neutral-04)", 'text-align': "left"}}>don't just look at analytics, <span style={{color: "var(--blue-04)", display: "inline"}}>understand</span> them</h2>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Img 
            fluid={data.ui_cards.childImageSharp.fluid}
            alt="Application user interface cards."
            style={{
              borderRadius: 16
            }}
          />
        </GridSpan>

        <GridSpan span={[12,12,12,12]}>
          <Card>
            <GridSpan span={[2,1,1,1]}>
              <Img
                fluid={data.mockup_streamingSources.childImageSharp.fluid}
                alt="Streaming sources for the application."
                style={{
                  width: "100%",
                  margin: "auto",
                  justifySelf: "stretch",
                  background: "var(--blue-07)",
                  borderRadius: 8
                }}
              />
            </GridSpan>
            <GridSpan span={[2,1,1,1]} justifyItems="start">
              <h2 style={{textAlign: "left", width: "100%"}}>unified data,<br/>clear insights</h2>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>Leverage intuitive dashboards to understand all your important metrics.</h5>
              <Button
                to="/waitlist"
                btnType="secondary"
                icon={<FontAwesome.FaArrowRight/>}
              >
                join waitlist
              </Button>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Img 
            fluid={data.icon_cloud.childImageSharp.fluid}
            alt="Icons of data sources."
            style={{
              borderRadius: 16
            }}
          />
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Card>
            <GridSpan span={[2,2,2,2]}>
              <h2 style={{textAlign: "left", width: "100%"}}>learn from your data</h2>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>Bring data from all your platforms into one place.</h5>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>Use tailored insights to fully understand your journey as a musician.</h5>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Card>
            <GridSpan span={[2,2,2,2]}>
              <div className="h3" style={{display: "flex", alignItems: "center", color: "var(--blue-05)"}}><FontAwesome.FaHeadphones/><h3 style={{marginLeft: "0.75em", color: "var(--neutral-01)"}}>streaming platforms</h3></div>
              <div
                style={{
                  height: 2,
                  background: "var(--neutral-05)",
                  width: "100%"
                }}
              ></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Top Songs</h5></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Listeners, Streams, and Saves</h5></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Related Artists</h5></div>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,6,6,6]}>
          <Card>
            <GridSpan span={[2,2,2,2]}>
              <div className="h3" style={{display: "flex", alignItems: "center", color: "var(--blue-05)"}}><FontAwesome.FaUsers/><h3 style={{marginLeft: "0.75em", color: "var(--neutral-01)"}}>social media</h3></div>
              <div
                style={{
                  height: 2,
                  background: "var(--neutral-05)",
                  width: "100%"
                }}
              ></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Follower Growth</h5></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Likes, Comments, and Reach</h5></div>
              <div className="h5" style={{display: "flex", alignItems: "center", color: "var(--neutral-04)"}}><FontAwesome.FaCheckCircle/><h5 style={{marginLeft: "0.75em", color: "var(--neutral-02)"}}>Engagement Rates</h5></div>
            </GridSpan>
          </Card>
        </GridSpan>

        <GridSpan span={[12,12,12,12]}>
          <Card>
            <GridSpan span={[2,1,1,1]} justifyItems="start">
              <h2 style={{textAlign: "left", width: "100%"}}>ready to get started?</h2>
              <h5 style={{textAlign: "left", color: "var(--neutral-03)"}}>We’re currently adding some finishing touches. <br/>In the meantime, make sure to join the waitlist to reserve your spot when we launch!</h5>
              <Button
                to="/waitlist"
                btnType="primary"
                icon={<FontAwesome.FaArrowRight/>}
              >
                join waitlist
              </Button>
            </GridSpan>
            <GridSpan span={[2,1,1,1]} order="-1">
              <Img
                fluid={data.mockup_onDesk.childImageSharp.fluid}
                alt="Streaming sources for the application."
                style={{
                  width: "100%",
                  background: "var(--blue-07)",
                  borderRadius: 8
                }}
              />
            </GridSpan>
          </Card>
        </GridSpan>

      </Layout>  )
}

export const query = graphql`
  query IndexQuery {
    mockup_insights: file(relativePath: { eq: "Index/mockup_insights.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ui_cards: file(relativePath: { eq: "Index/ui_cards.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mockup_streamingSources: file(relativePath: { eq: "Index/mockup_streamingSources.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bench_guitarman: file(relativePath: { eq: "Index/guitar_benchman.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    icon_cloud: file(relativePath: { eq: "Index/icon_cloud.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mockup_onDesk: file(relativePath: { eq: "Index/mockup_onDesk.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(maxWidth: 720){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }
`
// export default HomePage